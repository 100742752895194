import styled from "styled-components";
import logo from "src/assets/logo.svg";
const HeaderWrapper = styled.div`
  position: top;
  background: #fff;
  border: 0px solid #ddd;
  border-bottom-width: 1px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Header = (props) => {
  return (
    <HeaderWrapper>
      <img src={logo} height="45px" style={{ marginLeft: 20 }} />
      <div style={{ marginRight: 100, fontSize: 20, color: "#333" }}>
        Hub Status:{" "}
        {props.system_status.hub_connected ? "connected" : "disconnected"}
      </div>
    </HeaderWrapper>
  );
};
