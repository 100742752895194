import { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Control } from "./pages/control2";
import { Login } from "./pages/login";
function Router() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/control" element={<Control />} />
    </Routes>
  );
}

export default Router;
