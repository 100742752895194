import React from "react";

import io from "socket.io-client";
import { cloneDeep } from "lodash";
import styled from "styled-components";
import config from "src/config";
import { SpinnerRoundOutlined } from "spinners-react";
import { FileList } from "src/components/file_list";
import { PatientDetails } from "src/components/patient_details";
import { Button, Header, LoggingStatus } from "src/components";
import dayjs from "dayjs";
import { getToken } from "src/utils/auth";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryThreeQuarters,
  faTablet,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import "antd/dist/antd.css";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const PageContainer = styled.div`
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: calc(10px + 2vmin);
  color: #333;
`;

const ContentWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
`;
const Card = styled.div`
  padding: 10px;
  border: 1px solid #666;
  border-radius: 5px;
  margin-bottom: 10px;
  min-width: 10em;
`;
const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const MacAddress = styled.span`
  font-size: 16px;
`;
const PatientId = styled.span`
  margin-left: 5px;
  font-size: 20px;
`;

const BatteryWrap = styled.div`
  // padding-left: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
`;

const get_dot_color = (status) => {
  // if (status.downloading_status === "complete") return "#ff4f00";
  if (!status.configuring && !status.configured) return "gray";
  else if (status.configuring) return "yellow";
  else if (status.configured) return "#39ff14";
};
const StatusDot = styled.div`
  background: ${(props) => get_dot_color(props.status)};
  width: 15px;
  height: 15px;
  border-radius: 8px;
  margin-right: 3px;
`;
const HubStatusDot = styled.div`
  background: ${(props) => get_dot_color(props.status)};
  width: 15px;
  height: 15px;
  border-radius: 8px;
  margin-right: 3px;
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #555;
  margin-left: 5px;
  margin-right: 5px;
  padding: 3px;
  border-radius: 3px;
  font-size: 10px;
`;

const LoggingText = styled.div`
  color: ${(props) => (props.logging ? "#39ff14" : "gray")};
  font-size: 12px;
  text-align: left;
`;

export class Sensors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: {},
      system_status: { process_state: "" },
      files: [],
      patient: null,
    };
  }
  componentDidMount() {}
  reset = (address) => () => {
    this.prosp.socket.emit(
      "hub_message",
      JSON.stringify({ type: "reset_sensor", address })
    );
  };

  start_log = (address) => () => {
    this.props.socket.emit(
      "hub_message",
      JSON.stringify({ type: "start_logging", address })
    );
  };
  download_log = (address) => () => {
    this.props.socket.emit(
      "hub_message",
      JSON.stringify({ type: "download_log", address })
    );
  };

  get_progress = (address) => () => {
    this.props.socket.emit(
      "hub_message",
      JSON.stringify({ type: "get_logging_progress", address })
    );
  };

  render() {
    const { files, patient } = this.state;
    const {
      devices,
      scan_for_sensors,
      scan_for_sensors_spin,
      system_status,
      restart_hub,
    } = this.props;

    return (
      <ContentWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            text={"Re-scan USB Connections"}
            onClick={scan_for_sensors}
            spin={scan_for_sensors_spin}
          />
          <Button text={"Re-start Hub"} onClick={restart_hub} />
          {Object.entries(devices)
            .reduce((accum, [address, data]) => {
              data.address = address;
              accum.push(data);
              return accum;
            }, [])
            .sort((a, b) => (a.connected && !b.connected ? -1 : 1))
            .map((data) => (
              <Card key={data.address}>
                <CardHeader>
                  <FontAwesomeIcon
                    icon={faTablet}
                    style={{
                      fontSize: 20,
                      marginRight: 3,
                      color: data.connected ? "green" : "gray",
                    }}
                  />
                  <MacAddress>#{data.number}</MacAddress>
                  <LoggingText>{data.address}</LoggingText>
                </CardHeader>

                <LoggingStatus
                  connected={data.connected}
                  is_logging={data.is_logging}
                  logging_start_time={data.logging_start_time}
                />

                <div>
                  {data.connected && !data.is_logging ? (
                    <button onClick={this.start_log(data.address)}>
                      <LoggingText>start logging</LoggingText>
                    </button>
                  ) : null}
                  {data.connected && data.is_logging ? (
                    <button onClick={this.download_log(data.address)}>
                      <LoggingText>download log</LoggingText>
                    </button>
                  ) : null}
                  {data.connected && data.is_logging ? (
                    <button onClick={this.reset(data.address)}>
                      <LoggingText>reset</LoggingText>
                    </button>
                  ) : null}

                  {/*<button onClick={this.get_progress(address)}>
                  <LoggingText>get progress</LoggingText>
                </button>*/}
                </div>

                {/*<LoggingText logging={data.logging}>
                logging: {data.logging ? "yes" : "no"} last_count:{" "}
                {data.last_count}
              </LoggingText>*/}

                {data.connected &&
                data.downloading_status === "count_update" ? (
                  <LoggingText>
                    downloading:{" "}
                    {data.downloading_status === "complete"
                      ? "complete"
                      : data.download_count}{" "}
                    of {data.log_length}
                  </LoggingText>
                ) : null}
              </Card>
            ))}
        </div>
      </ContentWrapper>
    );
  }
}
