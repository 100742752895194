import React from "react";
import styled from "styled-components";
import dayjs from "dayjs";

const FileRow = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
`;
const FileColumn = styled.div`
  padding: 5px;
  flex-grow: 1;
`;

export const FileList = (props) => {
  return (
    <React.Fragment>
      <div>
        {props.files ? (
          <FileRow>
            <FileColumn>Downloaded at</FileColumn>
            <FileColumn>Address</FileColumn>
            <FileColumn>Filename</FileColumn>
          </FileRow>
        ) : null}
      </div>
      <div>
        {props.files &&
          props.files.map((file) => (
            <FileRow key={file.filename}>
              <FileColumn>
                {dayjs(file.created_on).format("ddd MMM D, YYYY - h:mm:ss A")}
              </FileColumn>
              <FileColumn>{file.sensor_mac}</FileColumn>
              <FileColumn>{file.filename}</FileColumn>
            </FileRow>
          ))}
      </div>
    </React.Fragment>
  );
};
