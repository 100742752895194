import React, { useState, useEffect } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { Button, Error } from "src/components";
import axios from "axios";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Label = styled.div`
  font-size: 16px;
  margin-right: 10px;
  font-weight: 700;
`;
const Input = styled.input`
  font-size: 14px;
  border: 0px;
  border-radius: 3px;
  padding: 6px;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
`;

export const PatientDetails = (props) => {
  const patient = props.patient;
  const [name, setName] = useState((patient && patient.name) || "");
  const [error, setError] = useState(null);
  const [spin, setSpin] = useState(false);
  useEffect(() => {
    if (patient && patient.name) setName(patient.name);
  }, [patient]);
  if (!patient) return null;

  const updatePatient = (patient_name) => () => {
    console.log("updating patient", patient_name);
    setSpin(true);
    axios
      .post("/update_patient", { patient_name })
      .then((res) => {
        setSpin(false);
      })
      .catch((err) => {
        setError(err.response.data.message);
        setSpin(false);
        setTimeout(() => setError(null), 2000);
      });
  };
  return (
    <React.Fragment>
      <Wrapper>
        <Label>Patient</Label>
        <Input
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setError(null);
          }}
        />
        <Button onClick={updatePatient(name)} text={"update"} spin={spin} />
      </Wrapper>
      {error ? (
        <div>
          <Error message={error} />
        </div>
      ) : null}
    </React.Fragment>
  );
};
