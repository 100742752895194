import React from "react";
import styled from "styled-components";
import { Button } from "src/components";
import axios from "src/utils/requests";
import { combinePatientsAndSensors } from "./control2";
import "antd/dist/antd.css";
import { Select } from "antd";
const { Option } = Select;

const ContentWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
`;

const Input = styled.input`
  border-radius: 3px;
  border: 0px;
  padding: 5px;
  font-size: 16px;
  margin-bottom: 10px;
`;
export class AddPatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      patient_id: "",
      selected_sensors: [],
      all_sensors: [],
    };
  }

  componentDidMount = () => {
    if (this.props.patient_id) {
      axios.get(`/patient/${this.props.patient_id}`).then((res) => {
        let patient = combinePatientsAndSensors(res.data)[0];
        this.setState({
          name: patient.name,
          selected_sensors: patient.sensors,
          all_sensors: res.data.devices,
        });
      });
    }
  };

  setField = (key, value) => this.setState({ [key]: value });

  handleChange = (value) => {
    let new_sensor = this.state.all_sensors.find((s) => s.mac === value);
    let selected_sensors = [...this.state.selected_sensors];
    selected_sensors.push(new_sensor);
    console.log("selected_sensors", selected_sensors);
    this.setState({ selected_sensors });
  };
  removeSensor = (mac) => {
    // let new_sensor = this.state.all_sensors.find((s) => s.mac === value);
    let selected_sensors = [...this.state.selected_sensors].filter(
      (s) => s.mac !== mac
    );
    // selected_sensors.push(new_sensor);
    console.log("selected_sensors", selected_sensors);
    this.setState({ selected_sensors });
  };
  savePatient = () => {
    const { patient_id, savePatientCallback } = this.props;
    const { name, selected_sensors } = this.state;
    let url = "/patient";
    if (patient_id) url = `/patient/${patient_id}`;
    axios
      .post(url, { patient_id, name, selected_sensors })
      .then((res) => savePatientCallback());
  };

  render() {
    const { name, all_sensors, selected_sensors } = this.state;
    const { patient_id, showAddPatient } = this.props;
    let sensor_list = all_sensors.filter((s) => s.patient_id === null);
    return (
      <ContentWrapper>
        patient id{" "}
        <Input
          type="text"
          placeholder="patient id"
          value={name}
          onChange={(e) => {
            this.setField("name", e.target.value);
          }}
        />
        sensors
        {selected_sensors.map((sensor) => (
          <div>
            {sensor.number} - {sensor.mac}{" "}
            <div onClick={() => this.removeSensor(sensor.mac)}>x</div>
          </div>
        ))}
        <Select style={{ width: 200 }} onChange={this.handleChange}>
          {sensor_list.map((sensor) => (
            <Option value={sensor.mac}>{sensor.number}</Option>
          ))}
        </Select>
        <Button text={"Cancel"} onClick={() => showAddPatient(false)} />
        <Button
          text={patient_id ? "Update" : "Add"}
          onClick={this.savePatient}
        />
      </ContentWrapper>
    );
  }
}
