import React from "react";
import styled from "styled-components";

const ErrorComponent = styled.div`
  font-size: 14px;
  color: red;
`;

export const Error = (props) => {
  return (
    <div>
      <ErrorComponent>{props.message}</ErrorComponent>
    </div>
  );
};
