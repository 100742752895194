import styled from "styled-components";
import { SpinnerCircularFixed } from "spinners-react";

const ButtonComponent = styled.button`
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  color: white;
  background: #30ade3;
  border: 0px;
  border-radius: 3px;
  display: inline-flex;
  cursor: pointer;
`;

export const Button = (props) => {
  return (
    <ButtonComponent {...props}>
      {props.text}
      {props.spin ? (
        <SpinnerCircularFixed
          size={16}
          thickness={100}
          speed={250}
          color="white"
          secondaryColor="transparent"
          style={{ marginLeft: 10 }}
        />
      ) : null}
    </ButtonComponent>
  );
};
