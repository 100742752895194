import React from "react";
import axios from "src/utils/requests";

import io from "socket.io-client";
import { cloneDeep } from "lodash";
import styled from "styled-components";
import config from "src/config";
import { SpinnerRoundOutlined } from "spinners-react";
import { FileList } from "src/components/file_list";
import { PatientDetails } from "src/components/patient_details";
import { Button, Header } from "src/components";
import dayjs from "dayjs";
import { getToken } from "src/utils/auth";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryThreeQuarters,
  faTablet,
} from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import "antd/dist/antd.css";
import { Tabs } from "antd";
import { Patients } from "../patients";
import { Sensors } from "../sensors";
import { AddPatient } from "../add_patient";
import { Admin } from "../admin";

const { TabPane } = Tabs;

const PageContainer = styled.div`
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: calc(10px + 2vmin);
  color: #333;
`;

const ContentWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
`;
const Card = styled.div`
  padding: 10px;
  border: 1px solid #666;
  border-radius: 5px;
  margin-bottom: 10px;
  min-width: 10em;
`;
const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SensorText = styled.span`
  font-size: 16px;
`;
const PatientId = styled.span`
  margin-left: 5px;
  font-size: 20px;
`;

const BatteryWrap = styled.div`
  // padding-left: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
`;

const get_dot_color = (status) => {
  // if (status.downloading_status === "complete") return "#ff4f00";
  if (!status.configuring && !status.configured) return "gray";
  else if (status.configuring) return "yellow";
  else if (status.configured) return "#39ff14";
};

const LoggingText = styled.div`
  color: ${(props) => (props.logging ? "#39ff14" : "gray")};
  font-size: 12px;
  text-align: left;
`;
//this takes the returned data direclty from the db rows, not configured for the device objects
export const combinePatientsAndSensors = ({ patients, devices }) => {
  patients.forEach((patient) => {
    patient.sensors = devices.filter((d) => d.patient_id === patient.id);
  });
  console.log("patients", patients);
  return patients;
};

export class Control extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: {},
      system_status: {},
      files: [],
      patients: [],
      scan_for_sensors_spin: false,
      showing_add_patient: false,
      editing_patient_id: null,
    };
    this.socket = null;
  }

  componentDidMount() {
    const token = getToken();
    this.socket = io(config.socket_url, {
      auth: { token },
      query: { source: "ui" },
    });
    this.socket.on("token_error", (args) => {
      alert("error with authentication, please log back in");
    });

    this.getPatientList();
    this.socket.on("send_to_ui", (args) => {
      args = JSON.parse(args);
      if (args.type === "device_list_state") {
        // console.log(args);
        this.setState(
          { devices: args.devices, scan_for_sensors_spin: false },
          () => this.getPatientList(true)
        );
      } else if (args.type === "system_status") {
        console.log("system_status", args);
        this.setState({ system_status: args });
      }
    });
  }

  getPatientList = (ignore_set_devices = false) => {
    Promise.all([axios.get("/device_list"), axios.get("/patient_list")]).then(
      ([devices, patient_data]) => {
        let state = { patients: combinePatientsAndSensors(patient_data.data) };
        if (!ignore_set_devices) state.devices = devices.data;
        this.setState(state);
      }
    );
  };

  savePatientCallback = () => {
    this.setState({ showing_add_patient: false });
    this.getPatientList();
  };

  scan_for_sensors = () => {
    this.setState({ scan_for_sensors_spin: true }, () => {
      this.socket.emit(
        "hub_message",
        JSON.stringify({ type: "scan_for_sensors" })
      );
    });
  };

  showAddPatient = (showing_add_patient, patient_id = null) =>
    this.setState({ showing_add_patient, editing_patient_id: patient_id });

  restart_hub = () => {
    this.socket.emit("restart_hub");
  };

  render() {
    const {
      devices,
      files,
      system_status,
      patient,
      scan_for_sensors_spin,
      showing_add_patient,
      patients,
      editing_patient_id,
    } = this.state;

    return (
      <PageContainer>
        <Header connected={false} system_status={system_status} />
        <ContentWrapper>
          {!showing_add_patient ? (
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane tab="Patients" key="1">
                <Patients
                  patients={patients}
                  scan_for_sensors={this.scan_for_sensors}
                  scan_for_sensors_spin={scan_for_sensors_spin}
                  showAddPatient={this.showAddPatient}
                />
              </TabPane>
              <TabPane tab="Sensors" key="2">
                <Sensors
                  socket={this.socket}
                  devices={devices}
                  scan_for_sensors={this.scan_for_sensors}
                  scan_for_sensors_spin={scan_for_sensors_spin}
                  restart_hub={this.restart_hub}
                />
              </TabPane>
              <TabPane tab="Data" key="3">
                Will a table of files live here?
              </TabPane>
              <TabPane tab="Admin" key="4">
                <Admin socket={this.socket} />
              </TabPane>
            </Tabs>
          ) : (
            <AddPatient
              showAddPatient={this.showAddPatient}
              patient_id={editing_patient_id}
              savePatientCallback={this.savePatientCallback}
            />
          )}
        </ContentWrapper>
      </PageContainer>
    );
  }
}
