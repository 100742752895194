import React from "react";
import axios from "src/utils/requests";

import io from "socket.io-client";
import { cloneDeep } from "lodash";
import styled from "styled-components";
import config from "src/config";
import { SpinnerRoundOutlined } from "spinners-react";
import { FileList } from "src/components/file_list";
import { PatientDetails } from "src/components/patient_details";
import { Button, Header, LoggingStatus } from "src/components";
import dayjs from "dayjs";
import { getToken } from "src/utils/auth";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryThreeQuarters,
  faTablet,
} from "@fortawesome/free-solid-svg-icons";
import { faUser, faEdit } from "@fortawesome/free-regular-svg-icons";
import "antd/dist/antd.css";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const PageContainer = styled.div`
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: calc(10px + 2vmin);
  color: #333;
`;

const ContentWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
`;
const Card = styled.div`
  padding: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  border: 1px solid #666;
  border-radius: 5px;
  margin-bottom: 10px;
  min-width: 10em;
`;
const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 500px;
`;
const SensorText = styled.span`
  font-size: 16px;
`;
const PatientId = styled.span`
  margin-left: 5px;
  font-size: 16px;
`;

const BatteryWrap = styled.div`
  // padding-left: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
`;

const get_dot_color = (status) => {
  // if (status.downloading_status === "complete") return "#ff4f00";
  if (!status.configuring && !status.configured) return "gray";
  else if (status.configuring) return "yellow";
  else if (status.configured) return "#39ff14";
};
const StatusDot = styled.div`
  background: ${(props) => get_dot_color(props.status)};
  width: 15px;
  height: 15px;
  border-radius: 8px;
  margin-right: 3px;
`;
const HubStatusDot = styled.div`
  background: ${(props) => get_dot_color(props.status)};
  width: 15px;
  height: 15px;
  border-radius: 8px;
  margin-right: 3px;
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #555;
  margin-left: 5px;
  margin-right: 5px;
  padding: 3px;
  border-radius: 3px;
  font-size: 10px;
`;

const LoggingText = styled.div`
  color: ${(props) => (props.logging ? "#39ff14" : "gray")};
  font-size: 12px;
  text-align: left;
`;

const get_status_text = (status) => {
  // if (status.downloading_status) return status.downloading_status;
  if (!status.configuring && !status.configured) return "not connected";
  else if (status.configuring) return "setting up";
  else if (status.configured) return "connected";
};
export class Patients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: {},
      system_status: { process_state: "" },
      files: [],
      patient: null,
    };
    this.socket = null;
  }

  render() {
    const { devices, files, system_status, patient } = this.state;
    const {
      scan_for_sensors,
      scan_for_sensors_spin,
      showAddPatient,
      patients,
    } = this.props;

    return (
      <ContentWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "flex-start",
          }}
        >
          <Button text={"+ Patient"} onClick={() => showAddPatient(true)} />
          <Button
            text={"Re-scan USB Connections"}
            onClick={scan_for_sensors}
            spin={scan_for_sensors_spin}
          />
        </div>

        {patients.map((p) => (
          <Card key={p.name}>
            <CardHeader>
              <div>
                <FontAwesomeIcon icon={faUser} style={{ fontSize: 20 }} />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PatientId>{p.name}</PatientId>
                <FontAwesomeIcon
                  icon={faEdit}
                  style={{
                    fontSize: 16,
                    color: "#bbb",
                    marginRight: 3,
                    cursor: "pointer",
                  }}
                  onClick={() => showAddPatient(true, p.id)}
                />
              </div>
            </CardHeader>
            {p.sensors &&
              p.sensors.map((sensor) => {
                let data = JSON.parse(sensor.data);
                let connected = data.connected;
                let is_logging = data.is_logging;
                let logging_start_time = data.logging_start_time;
                return (
                  <div>
                    <FontAwesomeIcon
                      icon={faTablet}
                      style={{
                        fontSize: 20,
                        color: connected ? "green" : "#bbb",
                        marginRight: 3,
                      }}
                    />

                    <SensorText>
                      {sensor.patient_hand === "left" ? "L" : "R"}: #
                      {sensor.number}
                    </SensorText>
                    <LoggingStatus
                      connected={connected}
                      is_logging={is_logging}
                      logging_start_time={logging_start_time}
                    />
                  </div>
                );
              })}
          </Card>
        ))}
      </ContentWrapper>
    );
  }
}
