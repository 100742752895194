import React from "react";
import axios from "src/utils/requests";

import io from "socket.io-client";
import { cloneDeep } from "lodash";
import styled from "styled-components";
import config from "src/config";
import { SpinnerRoundOutlined } from "spinners-react";
import { FileList } from "src/components/file_list";
import { PatientDetails } from "src/components/patient_details";
import { Button, Header, LoggingStatus } from "src/components";
import dayjs from "dayjs";
import { getToken } from "src/utils/auth";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryThreeQuarters,
  faTablet,
} from "@fortawesome/free-solid-svg-icons";
import { faUser, faEdit } from "@fortawesome/free-regular-svg-icons";
import "antd/dist/antd.css";

const ContentWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
`;
const Input = styled.input`
  border-radius: 3px;
  border: 1px solid #bbb;
  padding: 5px;
  font-size: 16px;
  margin-right: 10px;
`;

const get_status_text = (status) => {
  // if (status.downloading_status) return status.downloading_status;
  if (!status.configuring && !status.configured) return "not connected";
  else if (status.configuring) return "setting up";
  else if (status.configured) return "connected";
};
export class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filename: null };
  }

  reattemptFileUpload = () => {
    if (this.state.filename)
      this.props.socket.emit(
        "reattempt_file_upload",
        JSON.stringify({ filename: this.state.filename })
      );
  };
  hubFileList = () => {
    this.props.socket.emit("get_hub_file_list");
  };
  hubGetStats = () => {
    this.props.socket.emit("get_hub_stats");
  };
  render() {
    const { filename } = this.state;

    return (
      <ContentWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Input
            type="text"
            placeholder="filename"
            value={filename}
            onChange={(e) => {
              this.setState({ filename: e.target.value });
            }}
          />
          <div>
            <Button
              text={"Re-attempt upload"}
              onClick={this.reattemptFileUpload}
            />
          </div>
          <div>
            <Button text={"Get hub file list"} onClick={this.hubFileList} />
          </div>
          <div>
            <Button text={"Get hub stats"} onClick={this.hubGetStats} />
          </div>
        </div>
      </ContentWrapper>
    );
  }
}
