import React, { useState } from "react";
import axios from "src/utils/requests";
import "./style.css";
import styled from "styled-components";
import logo from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { SpinnerCircularFixed } from "spinners-react";
import { setToken } from "src/utils/auth";
import { Button, Error } from "src/components";

const Input = styled.input`
  border-radius: 3px;
  border: 0px;
  padding: 5px;
  font-size: 16px;
  margin-bottom: 10px;
`;

const Img = styled.img`
  margin-bottom: 3em;
`;

export const Login = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const emailvalid = (value) =>
    value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())
      ? true
      : false;

  const handleLogin = (e) => {
    e.preventDefault();
    if (!emailvalid(email)) {
      console.log(emailvalid(email));
      setError("please enter a valid email");
      return;
    }
    setLoading(true);
    axios
      .post("/login", {
        email,
        password,
      })
      .then((res) => {
        const data = res.data;
        if (data.token) {
          setToken(data.token);
          navigate("/control");
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          setError(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleLogin(e);
    }
  };

  return (
    <div className="bg">
      <div className="login-card">
        <div className="login-form">
          <Img src={logo} />
          <div>
            <Input
              type="text"
              placeholder="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError(null);
              }}
              onKeyDown={onKeyPress}
            />
          </div>
          <div>
            <Input
              type="password"
              placeholder="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(null);
              }}
              onKeyDown={onKeyPress}
            />
          </div>
          <Button onClick={handleLogin} text={"Sign In"} spin={loading} />
          {error ? <Error message={error} /> : null}
        </div>
      </div>
    </div>
  );
};
