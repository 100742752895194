import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faBatteryThreeQuarters,
  faTablet,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

const LoggingText = styled.div`
  color: ${(props) => (props.logging ? "#39ff14" : "gray")};
  font-size: 12px;
  text-align: left;
`;
const SmallText = styled.div`
  color: ${(props) => (props.logging ? "#39ff14" : "gray")};
  font-size: 10px;
  text-align: left;
`;

const SmallTextBold = styled.div`
  color: ${(props) => (props.logging ? "#39ff14" : "gray")};
  font-size: 10px;
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 700;
`;

const displayTimeDiff = (start_time) => {
  let time_diff = new Date() - new Date(start_time);
  let days = Math.floor(time_diff / 1000 / (24 * 60 * 60));
  let hours = Math.floor(time_diff / 1000 / (60 * 60)) - days * 24;
  let minutes = Math.floor(time_diff / 1000 / 60) - days * 24 * 60 - hours * 60;
  let seconds =
    Math.floor(time_diff / 1000) -
    days * 24 * 60 * 60 -
    hours * 60 * 60 -
    minutes * 60;
  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

export class LoggingStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start_time: props.start_time,
    };
  }
  componentDidMount = () => {
    setInterval(() => this.setState({}), 1000);
  };

  render() {
    const { connected, is_logging, logging_start_time } = this.props;
    return (
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          width: 400,
          alignItems: "center",
        }}
      >
        <LoggingText>logging</LoggingText>
        {is_logging ? (
          <React.Fragment>
            <FontAwesomeIcon
              icon={faCheck}
              style={{
                fontSize: 12,
                lineHeight: 16,
                marginLeft: 5,
                color: "green",
              }}
            />
            {logging_start_time ? (
              <React.Fragment>
                <SmallTextBold>
                  {displayTimeDiff(logging_start_time)}
                </SmallTextBold>
                <SmallText>
                  (started:{" "}
                  {moment(new Date(logging_start_time)).format(
                    "HH:mm:ss MMM DD, YYYY"
                  )}
                  )
                </SmallText>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : (
          <FontAwesomeIcon
            icon={faTimes}
            style={{
              fontSize: 12,
              lineHeight: 16,
              marginLeft: 5,
              color: "red",
            }}
          />
        )}
      </div>
    );
  }
}
